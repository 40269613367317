body {
	color: $font-color;
	font-size: 15px;
}

#bg {
	height: 100vh;
	position: fixed;
	width: 100vw;
	z-index: -9999;
	@include bg-blur(1px);
}

.blur-bg {
	background-image: $bg;
	background-attachment: fixed;
	background-size: cover;
    filter: blur(5px);
		-webkit-filter: blur(5px);
	    -moz-filter: blur(5px);
	    -ms-filter: blur(5px);
	    -o-filter: blur(5px);
	height: 150%;
	left: -10px;
	position: absolute;
	top: -10px;
	width: 150%;
	z-index: -9999;
}

// Text shadow
.navbar,
.session-flash,
.welcome h1,
.welcome p,
.panel,
.note .title,
.note p,
.folder .title
{
	text-shadow: 0 0 3px #000;
}

// Clearfix
.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

// Font Awesome
.fa {
	font-size: 20px;
}

// Hover 
.hover {
	cursor: pointer;
	transition: all 500ms;
}

.hover:hover {
	background-color: $action-color !important;
	text-decoration: none;
	@include transition;
}

// Buttons
.button {
	background-color: #7e7e7e;
	border: none;
	color: #fff;
	padding: 10px;
}

.button.login {
	background-color: $create-color;
}

.button.register {
	background-color: $yes-color;
}

.welcome {
	border: 2px solid $border-color;
	padding: 15px;
	text-align: center;
	@include blur-bg-parent();

	img {
		width: 50%;
	}

	a.login,
	a.register {
		color: #fff;
		display: inline-block;
		font-size: 40px;
		margin: 20px;
		padding: 40px;

		.fa {
			font-size: 40px;
		}
	}

	a.login {
		background-color: $yes-color;
	}

	a.register {
		background-color: $create-color;
	}
}

// Cursor pointers
#new-note,
.notes .note .options {
	cursor: pointer;
}

.session-flash {
	border: 2px solid $border-color;
	margin-bottom: 15px;
	text-align: center;

	p {
		margin: 0;
		padding: 15px;
	}

}

// Login/register forms
.panel {
	border-color: $border-color;
	border-radius: 0;

	.panel-heading {
		background-color: transparent;
		border: none;
		color: $font-color;
	}

	.btn-link {
		color: $font-color;
	}

	input {
		background-color: transparent;
		border: 1px solid $border-color;
		border-radius: 0 !important;
		color: $font-color;
	}
}

.columns {
	column-count: 3;

	@media screen and (max-width: 768px) {
		column-count: 2;
	}

	@media screen and (max-width: 480px) {
		column-count: 1;
	}
}

.inline-block {
	display: inline-block;
}