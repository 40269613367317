// Create New Note //
#new-note {
	background-color: $create-color;
	break-inside: avoid-column;
	-webkit-column-break-inside: avoid;
	color: #fff;
	margin: 5px;
	padding: 10px;
	text-align: center;
	width: 100%;

	.fa.fa-sticky-note {
		font-size: 40px;
	}
}

#new-note-form,
form.note.update-note-form {
	display: none;
}

#new-note-form {
	
	div.title,
	div.content {
		padding: 5px;
	}

	#new-note-close {
		cursor: pointer;
		display: inline-block;
		float: right;
		padding: 10px 0;
		text-align: center;
		width: 40px;
	}

	button {
		background-color: $yes-color;
		border: none;
		color: #fff;
		display: inline-block;
		float: left;
		padding: 5px 0;
		text-align: center;
		width: 80%;
	}

	.fullscreen-edit {
		background-color: lighten($create-color, 10%);
		width: 20%;
	}
}

#new-note-form.fullscreen {
	height: 90vh;
	left: 5%;
	margin: 0;
	top: 5%;
	position: fixed;
	width: 90%;
	z-index: 9999;
	@include transition;

	.content {
		height: calc(100% - 120px);
		@include transition;
	}

	button,
	.fullscreen-edit {
		bottom: 0;
		position: absolute;
		@include transition;
	}

	.fullscreen-edit {
		left: 80%;
		@include transition;
	}
}

.notes {

	.note {
		background-color: transparent;
		border: 2px solid $border-color;
		break-inside: avoid-column;
		-webkit-column-break-inside: avoid;
		display: inline-block;
		margin: 5px;
		width: 100%;

		input, select {
			border-radius: 0 !important;
		}

		.title,
		.content {
			padding: 10px;
		}

		.note-sticky {
			border-bottom: 2px solid $border-color;
			min-height: 30px;
		}

		.title {
			display: inline-block;
			font-weight: bold;
			width: -webkit-calc(100% - 45px);
			width: -moz-calc(100% - 45px);
			width: calc(100% - 45px);
		}

		.options {
			display: inline-block;
			padding: 8px 0;
			text-align: center;
			width: 40px;
			
			.options-toggle {
				opacity: 0;
				@include transition;
			}

			.close-form {
				display: none;
			}
		}

		&:hover {
			.options {
				.options-toggle {
					opacity: 1;
					@include transition;
				}
			}
		}

		.options-bar {
			border-bottom: 2px solid $border-color;
			display: none;

			ul {
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					float: left;
					list-style-type: none;
					text-align: center;

					a {
						color: #fff;
						display: inline-block;
						padding: 5px 0;
						width: 100%;
					}
				}

				li.edit {
					width: 35%;

					a {
						background-color: $create-color;
					}

				}

				li.save {
					display: none;
					width: 35%;

					a {
						background-color: $yes-color;
					}
					
				}

				li.fullscreen {
					width: 35%;

					a {
						background-color: lighten($create-color, 10%);
					}
				}

				li.delete {
					width: 30%;

					a {
						background-color: $no-color;
					}
				}

			}
		}

		.update-note-form-title {
			display: none;
			margin: 0;
			padding: 5px;
			width: 100%;

			input {
				display: inline-block;
				width: calc(100% - 40px);
			}

			.close-form {
				cursor: pointer;
				display: inline-block;
				float: right;
				padding: 6px 0;
				text-align: center;
				width: 40px;
			}
		}

		.update-note-form {
			border: none;
			height: calc(100% - 120px); 
			width: calc(100% - 10px);

			button[type="submit"] {
				display: none;
			}
		}

	}

	.note.fullscreen {
		left: 5%;
		margin: 0;
		min-height: 90vh;
		top: 5%;
		@include transition;
		position: fixed;
		width: 90%;
		z-index: 9999;

		#content {
			height: 80vh;
			@include transition;
		}

		button,
		.fullscreen-edit {
			bottom: 0;
			position: absolute;
			@include transition;
		}

		.fullscreen-edit {
			left: 80%;
			@include transition;
		}
	}

}

.fullscreen-overlay {
	background-color: $meh-color;
	display: none;
	height: 100vh;
	left: 0;
	opacity: 0.75;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 9000;
}