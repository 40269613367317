#new-note-form.fullscreen {
	.cke_contents {
		height: -webkit-calc(80vh - 80px) !important;
		height: -moz-calc(80vh - 80px) !important;
		height: calc(80vh - 80px) !important;
	}
}

.note.fullscreen {
	.update-note-form {
		div {
			// height: 80vh !important;
		}
		.cke_contents {
			height: -webkit-calc(80vh - 80px) !important;
			height: -moz-calc(80vh - 80px) !important;
			height: calc(80vh - 80px) !important;
		}
	}
}

.cke_bottom {
	display: none !important;
}

// WYSIWYG interface removals
.cke,
.cke_chrome {
	border: none !important;
	box-shadow: none !important;
	.cke_inner {
		.cke_top {
			background: none;
			background-color: transparent;
			background-image: none;
			border: none;
			box-shadow: none;
			padding: 0;
			.cke_toolbox {
				.cke_toolbar {
					float: left;
					display: inline-block;
					}
					.cke_toolgroup {
						background: none;
						background-image: none;
						border: none;
						border-radius: 0;
						margin: 0;
						// &:hover {
						// 	background: none;
						// 	background-image: none;
						// 	border: none;
						// 	border-radius: 0;
						// 	margin: 0;
						// }
						a {
							&:hover {
								background: none;
								background-image: none;
								border: none;
								border-radius: 0;
								box-shadow: none;
								margin: 0;	
							}
						}
					}
					.cke_combo {
						a {
							background: none;
							background-image: none;
							border: none;
							border-radius: 0;
							box-shadow: none;
							margin: 0;
							&:hover {
								background: none;
								background-image: none;
								border: none;
								border-radius: 0;
								box-shadow: none;
								margin: 0;
							}
						}
					}
				}

			// Clipboard options
			.cke_toolbar:nth-child(1) {
				.cke_toolgroup {
					display: none;
				}
			}
			// Spell check
			.cke_toolbar:nth-child(2) {
				.cke_toolgroup {
					display: none;
				}
			}
			// Link options
			.cke_toolbar:nth-child(3) {
				.cke_toolgroup {
					display: none;
				}
			}
			// Images/tables/special characters
			.cke_toolbar:nth-child(4) {
				.cke_toolgroup {
					display: none;
				}
			}
			// Full screen edit
			.cke_toolbar:nth-child(5) {
				.cke_toolgroup {
					display: none;
				}
			}
			// Source code
			.cke_toolbar:nth-child(6) {
				.cke_toolgroup {
					display: none;
				}
			}
			// Text formatting
			.cke_toolbar:nth-child(8) {
				.cke_toolgroup {
					// display: none;
				}
			}
			// Bullets/quotes
			.cke_toolbar:nth-child(9) {
				.cke_toolgroup {
					// display: none;
				}
			}
			// About
			.cke_toolbar:nth-child(11) {
				.cke_toolgroup {
					display: none;
				}
			}
		}
	}
}

#new-note-form {
	.cke_editor_content:not(:first-of-type) {
		display: none !important;
	}
}

.update-note-form {
	.cke_editor_content:not(:nth-of-type(2)) {
		display: none !important;
	}
}
