// Colors
$yes-color: limegreen;
$no-color: crimson;
$create-color: skyblue;
$action-color: orange;
$meh-color: #3d3d3d;
$font-color: #f6f6f6;
$border-color: rgba(246,246,246,0.25);
$bg: url('/imgs/bg.jpg');

// Transition
@mixin transition {
	transition: all 100ms;
}

// Blur
@mixin bg-blur($px) {
	
	background-color: transparent;
	overflow: hidden;

	&:before {
		-webkit-filter: blur($px);
	    -moz-filter: blur($px);
	    -ms-filter: blur($px);
	    -o-filter: blur($px);
	    filter: blur($px);

		background-attachment: fixed;
		background-image: $bg;
		background-size: cover;
		content: "";
		display: block;
		height: 110vh;
		left: 0;
		margin: -10px;
		position: absolute;
		top: 0;
		transform: scale(1.01);
		width: 110vw;
		z-index: -9999;

	}
}

@mixin blur-bg-parent() {	
	
	overflow: hidden;
	position: relative;

}

.blur-bg-parent {
	background-color: transparent;
	overflow: hidden;
	position: relative;
}

