.navbar {
	border: none;
	border-bottom: 2px solid $border-color;
	border-top: 2px solid $border-color;
	color: $font-color;
	@include blur-bg-parent();
}

.navbar-default {
	background-color: transparent;

	.navbar-brand {
		color: $font-color;
		margin: 5px;
		padding: 0;

		&:hover {
			color: $font-color;
		}

		img {
			max-height: 100%;
			padding: 5px;
		}
		small {
			bottom: 18px;
			font-size: 50%;
			position: relative;
			right: -105%;
		}
	}
}

.navbar-nav {
	
	li a.dropdown-toggle {
		background-color: transparent !important;
		color: $font-color !important;
	}

	ul.nav-dropdown-menu {
		display: none;
		padding: 0;
		
		@media (min-width: 768px) {
			border: 2px solid $border-color;
			border-top: none;
			position: fixed;
		}

		li {
			list-style-type: none;
			
			a {
				display: inline-block;
				padding: 15px;
				text-decoration: none;
			}
		}
	}

	li {
		a {
			padding-bottom: 20px;
			padding-top: 20px;
		}
	}
}

@media (max-width: 768px - 1) {
	.navbar-collapse {
		border-top: none !important;
	}
}

nav.navbar {
	.navbar-nav {
		li {
			a {
				color: $font-color;
				@media screen and (max-width: 768px) {
					width: 100%;
				}

				&:hover {
					color: $font-color;
				}
			}
		}
	}
}

// Hamburger
@media (max-width: 768px - 1) {
	button.navbar-toggle {
		background-color: transparent !important;
		border: none;
		margin: 0;
		padding: 23px;

		.icon-bar {
			background-color: $font-color !important;
		}
	}
}