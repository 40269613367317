#new-folder {
	background-color: $create-color;
	break-inside: avoid-column;
	-webkit-column-break-inside: avoid;
	color: #fff;
	float: left;
	margin: 5px;
	padding: 10px;
	text-align: center;
	width: 100%;

	.fa.fa-folder {
		font-size: 40px;
	}
}

#new-folder-form {

	display: none;

	div.title {
		padding: 5px;
	}

	#new-folder-close {
		cursor: pointer;
		display: inline-block;
		float: right;
		padding: 10px 0;
		text-align: center;
		width: 40px;
	}

	button {
		background-color: $yes-color;
		border: none;
		color: #fff;
		display: inline-block;
		float: left;
		padding: 5px 0;
		text-align: center;
		width: 100%;
	}

	select {
		margin: 5px;
		width: -webkit-calc(100% - 10px);
		width: -moz-calc(100% - 10px);
		width: calc(100% - 10px);
	}
}

.folder {
	background-color: transparent;
	border: 2px solid $border-color;
	margin: 5px;
	width: 100%;

	.title {
		cursor: pointer;
		float: left;
		font-weight: bold;
		margin: 0;
		padding: 10px;
		width: -webkit-calc(100% - 40px);
		width: -moz-calc(100% - 40px);
		width: calc(100% - 40px);

		i {
			margin-right: 10px;
		}
	}

	.options {
		cursor: pointer;
		display: inline-block;
		padding: 10px;
		width: 40px;
	}

	.options {
			display: inline-block;
			text-align: center;
			width: 20px;
			
			.options-toggle {
				opacity: 0;
				@include transition;
			}

		}

	&:hover {
		.options {
			.options-toggle {
				opacity: 1;
				@include transition;
			}
		}
	}

	.options-bar {
			border-bottom: 2px solid $border-color;
			display: none;

			ul {
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					float: left;
					list-style-type: none;
					text-align: center;

					a {
						color: #fff;
						display: inline-block;
						padding: 5px 0;
						width: 100%;
					}
				}

				li.edit {
					width: 70%;

					a {
						background-color: $create-color;
					}

				}

				li.save {
					display: none;
					width: 70%;

					a {
						background-color: $yes-color;
					}
					
				}

				li.delete-folder {
					width: 30%;

					a {
						background-color: $no-color;
					}
				}

			}
		}

	.confirm-delete {
		display: none;
		text-align: center;

		p {
			padding: 5px;

			span {
				color: $no-color;
				font-weight: bold;
			}
		}

		a {
			background-color: $no-color;
			color: #fff;
			display: inline-block;
			padding: 5px;
			width: 100%;
		}

	}

	.update-folder-form {
		border: none;
		display: none;
		margin: 0;
		width: 100%;
		.update-folder-form-title {
			padding: 5px;
			width: 100%;

			input {
				display: inline-block;
				width: -webkit-calc(100% - 40px);
				width: -moz-calc(100% - 40px);
				width: calc(100% - 40px);
			}
		}

		.close-form {
			cursor: pointer;
			display: inline-block;
			float: right;
			padding: 6px 0;
			text-align: center;
			width: 40px;
		}

		button {
			display: none;
		}

		select {
			margin: 5px;
			width: -webkit-calc(100% - 10px);
			width: -moz-calc(100% - 10px);
			width: calc(100% - 10px);
		}
	}
}

#new-note-form {
	select {
		margin: 0 5px 5px 5px;
		width: -webkit-calc(100% - 10px);
		width: -moz-calc(100% - 10px);
		width: calc(100% - 10px);
	}
}

.update-note-form {
	select {
		margin-top: 5px;
	}
}

.folder .folder,
.folder .note {
	display: none;
	width: -webkit-calc(100% - 10px);
	width: -moz-calc(100% - 10px);
	width: calc(100% - 10px);
}
